import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import TextLink from 'components/TextLink'

const ServicesOverview = ({ classes }) => (
  <div className={classes.container}>
    <div className='row no-gutters'>
      <div className={`col-md-4 ${classes.image}`} />
      <div className={`col-md-8 ${classes.content}`}>
        <h4 className={classes.title}>SERVICES OVERVIEW</h4>
        <div className={classes.textLink}>
          <TextLink text='LEARN MORE' href='/services' />
        </div>
        <hr className={classes.topHR} />
        <div className='row'>
          <div className='col-lg-5'>
            <h2>Platform / Operations</h2>
          </div>
          <div className={`col-lg-7 ${classes.services}`}>
            <h4>IMPROVED CI/CD AUTOMATION</h4>
            <h4>CUSTOM DEVELOPER TOOLING</h4>
            <h4>DEPLOYMENT ON KUBERNETES</h4>
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-lg-5'>
            <h2>Software Development</h2>
          </div>
          <div className={`col-lg-7 ${classes.services}`}>
            <h4>RUBY / RAILS</h4>
            <h4>JAVASCRIPT / REACT</h4>
            <h4>ELIXIR / PHOENIX</h4>
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-lg-5'>
            <h2>Process & Training</h2>
          </div>
          <div className={`col-lg-7 ${classes.services}`}>
            <h4>PRACTICES & METHODOLOGY</h4>
            <h4>MENTORING & PAIR PROGRAMMING</h4>
          </div>
        </div>
      </div>
    </div>
    <div className={`row no-gutters ${classes.bottomBarContainer}`}>
      <div className='col-4' />
      <div className='col-8' />
    </div>
  </div>
)

export default injectSheet(style)(ServicesOverview)
