import { COLORS, SPACING, FONT_SIZES } from 'helpers/globals'
import { styleUpToMd } from 'helpers/screens'

export default {
  outerContainer: {
    display: 'inline-block',
    width: '100vw'
  },
  innerContainer: {
    display: 'flex',
    ...styleUpToMd({
      flexDirection: 'column',
      alignItems: 'center'
    })
  },
  authorContainer: {
    flexShrink: 0,
    padding: [0, SPACING.base],
    marginRight: SPACING.base,
    borderRight: `3px solid ${COLORS.darkGrey}`,
    maxWidth: 240,
    textAlign: 'center',
    ...styleUpToMd({
      borderRight: 0,
      borderBottom: `3px solid ${COLORS.darkGrey}`,
      marginRight: 0,
      width: '90%',
      maxWidth: '90%'
    })
  },
  authorImage: {
    height: 'auto',
    borderRadius: '50%',
    border: `3px solid ${COLORS.white}`,
    marginBottom: SPACING.small,
    maxWidth: '7rem'
  },
  authorName: {
    fontWeight: 'bold',
    letterSpacing: '.05rem',
    margin: 0
  },
  authorPosition: {
    color: COLORS.grey,
    fontSize: FONT_SIZES.regular
  },
  quoteContainer: {
    padding: [SPACING.small, 0],
    display: 'flex',
    alignItems: 'center'
  },
  quoteText: {
    fontSize: FONT_SIZES.large,
    fontWeight: 'bold',
    lineHeight: SPACING.large
  }
}
