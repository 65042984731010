import { COLORS, SPACING } from 'helpers/globals'

export default {
  container: {
    backgroundColor: COLORS.darkerGrey,
    color: COLORS.white,
    padding: `${SPACING.base} 0`
  },
  quoteScrollWrapper: {
    overflow: 'hidden'
  },
  quoteScroller: {
    paddingTop: SPACING.base,
    transition: 'margin-left .5s ease-in-out'
  },
  slashOuterContainer: {
    paddingBottom: SPACING.small
  },
  slashContainer: {
    position: 'relative',
    marginTop: SPACING.small,
    backgroundColor: COLORS.darkGrey,
    transform: 'skew(20deg)',
    display: 'flex'
  },
  slashOuterLink: {
    borderRight: `.75rem solid ${COLORS.darkerGrey}`,
    position: 'relative',
    zIndex: 2,
    cursor: 'pointer'
  },
  slashLink: {
    width: '75px',
    height: '18px',
    background: COLORS.transparent,
    display: 'flex',
    margin: '0 4px',
    boxSizing: 'border-box'
  },
  slashInner: {
    position: 'relative',
    zIndex: 2,
    alignSelf: 'center',
    backgroundColor: COLORS.darkerGrey,
    height: '10px',
    width: '100%'
  },
  redBox: {
    position: 'absolute',
    backgroundColor: COLORS.red,
    height: '100%',
    transition: 'left .5s ease-in-out'
  }
}
