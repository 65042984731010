import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'

const Quote = ({ id, alt, img, name, position, text, classes }) => (
  <div className={classes.outerContainer} id={id}>
    <div className='container'>
      <div className={classes.innerContainer}>
        <div className={classes.authorContainer}>
          <img className={classes.authorImage} src={img} alt={alt} />
          <p className={classes.authorName}>{name}</p>
          <p className={classes.authorPosition}>{position}</p>
        </div>
        <div className={classes.quoteContainer}>
          <p className={classes.quoteText}>{text}</p>
        </div>
      </div>
    </div>
  </div>
)

export default injectSheet(style)(Quote)
