import { COLORS } from 'helpers/globals'
import { styleUpToMd } from 'helpers/screens'

export default {
  container: {
    position: 'relative',
    backgroundColor: COLORS.red,
    padding: `6rem 0`,
    color: COLORS.white,
    overflow: 'hidden'
  },
  slash: {
    opacity: 0.9,
    position: 'absolute',
    bottom: '-43%',
    right: '10%',
    ...styleUpToMd({
      width: '225px'
    })
  }
}
