import React from 'react'
import Header from 'components/Header'
import CallToActionBlock from 'components/CallToActionBlock'
import GetToKnowUs from 'components/GetToKnowUs'
import OurProcess from 'components/OurProcess'
import ServicesOverview from 'components/ServicesOverview'
import Layout from 'components/Layout'
import QuoteSlider from 'components/QuoteSlider'

const Index = () => (
  <Layout>
    <Header siteTitle='hint.io' />
    <ServicesOverview />
    <OurProcess />
    <GetToKnowUs />
    <QuoteSlider />
    <CallToActionBlock
      title='Ready to Get Started?'
      linkText="LET'S TALK"
      linkHref='/contact'
    />
  </Layout>
)

export default Index
