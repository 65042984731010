import { COLORS, SPACING } from 'helpers/globals'
import headerBG from 'assets/images/joshWorking.jpg'
import { styleUpToMd, styleFromXl } from 'helpers/screens'

export default {
  outerContainer: {
    backgroundImage: `linear-gradient(${COLORS.transparent}, ${COLORS.darkGrey}), url(${headerBG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundColor: COLORS.darkGrey,
    ...styleUpToMd({
      backgroundSize: 'cover',
      backgroundPosition: `10% ${SPACING.xxl}`
    }),
    [`@media (max-width: 470px)`]: {
      backgroundPosition: `30% ${SPACING.xxl}`
    },
    ...styleFromXl({
      backgroundSize: 'cover',
      minHeight: '100vh'
    })
  },
  container: {
    position: 'relative',
    color: COLORS.white,
    borderTop: `5px ${COLORS.red} solid`,
    padding: `0 ${SPACING.xxxl}`,
    ...styleUpToMd({
      paddingLeft: SPACING.base,
      paddingRight: SPACING.base,
      paddingTop: `calc(${SPACING.xxxl} - 5px)`
    })
  },
  innerContainer: {
    display: 'block',
    marginTop: SPACING.small,
    paddingBottom: SPACING.xxl,
    ...styleUpToMd({
      paddingBottom: SPACING.base
    }),
    '& h1': {
      marginBottom: SPACING.base,
      marginTop: SPACING.base,
      lineHeight: SPACING.xxl,
      ...styleFromXl({
        marginTop: SPACING.xl
      })
    },
    '& h3': {
      fontWeight: 'normal',
      lineHeight: SPACING.large,
      marginTop: 0
    }
  },
  slants: {
    position: 'absolute',
    top: '-5%',
    right: '5%',
    pointerEvents: 'none',
    height: '50%',
    ...styleUpToMd({
      top: SPACING.xl
    })
  }
}
