import { COLORS, SPACING, FONT_SIZES } from 'helpers/globals'
import background from 'assets/images/get_to_know_us.jpg'

export default {
  container: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: `6rem 0`,
    color: COLORS.lightGrey,
    '& h5': {
      margin: 0,
      letterSpacing: SPACING.letters.small
    },
    '& hr': {
      marginBottom: SPACING.large,
      marginTop: '.2rem'
    },
    '& h2': {
      margin: `${SPACING.small} 0`
    }
  },
  linkContainer: {
    marginBottom: SPACING.large,
    marginTop: SPACING.base,
    fontSize: FONT_SIZES.small
  }
}
