import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import TextLink from 'components/TextLink'

const CallToActionBlock = ({ title, linkText, linkHref, classes }) => (
  <div className={classes.container}>
    <div className='container'>
      <h2 className={classes.heading}>{title}</h2>
      <TextLink text={linkText} href={linkHref} />
    </div>
  </div>
)

export default injectSheet(style)(CallToActionBlock)
