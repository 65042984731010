import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import Quote from './Quote'
import justin from 'assets/images/justin-l.jpg'
import beverly from 'assets/images/beverly-n.jpg'
import konstantin from 'assets/images/konstantin-g.jpg'

class QuoteSlider extends React.Component {
  constructor() {
    super()
    this.state = {
      selectedIndex: 0,
      quotes: [
        {
          img: beverly,
          alt: 'Beverly N',
          text: "We are so pleased with the collaboration, humble way of explaining, and extreme professionalism you've shown in this project we are discussing what increased engagement would look like.",
          name: 'Beverly N',
          position: 'CTO, Pure Charity'
        },
        {
          img: konstantin,
          alt: 'Konstantin G',
          text: 'Keep your teams releasing features while Hint folks upgrade your app in parallel. I literally can not recommend them enough.',
          name: 'Konstantin G',
          position: 'Platform Engineer, Homebase'
        },
        {
          img: justin,
          alt: 'Justin Luciani',
          text: "Hands down the best consulting firm I've worked with. They understand the full development cycle and do a great job of using creativity to design elegant solutions.",
          name: 'Justin Luciani',
          position: 'Founder of RecruitiFi'
        }
      ]
    }
  }

  calculateStyles() {
    return {
      left: `${(100 / this.state.quotes.length) * this.state.selectedIndex}%`,
      width: `${100 / this.state.quotes.length}%`
    }
  }

  scrollThings(index) {
    this.setState({
      selectedIndex: index
    })
  }
  render() {
    const { classes } = this.props
    const { selectedIndex, quotes } = this.state
    return (
      <div className={classes.container}>
        <div className='container'>
          <h2>
            Don't Take Our Word for It
            <hr />
          </h2>
          <p>We've worked with a lot of clients over the years. Here's what a few of them have to say about us:</p>
        </div>
        <div className={classes.quoteScrollWrapper}>
          <div className={classes.quoteScroller} style={{ width: `${100 * quotes.length}vw`, marginLeft: `-${100 * selectedIndex}vw` }}>
            { quotes.map((quote, i) => <Quote {...quote} key={i} />)}
          </div>
        </div>
        <div className={`d-flex justify-content-center ${classes.slashOuterContainer}`}>
          <div className={classes.slashContainer}>
            <div className={classes.redBox} style={this.calculateStyles()} />
            {quotes.map((quote, i) =>
              <div className={classes.slashOuterLink} key={i} onClick={this.scrollThings.bind(this, i)}>
                <div className={classes.slashLink}>
                  <div className={classes.slashInner} />
                </div>
              </div>)}
          </div>
        </div>
      </div>
    )
  }
}

export default injectSheet(style)(QuoteSlider)
