import { COLORS, SPACING } from 'helpers/globals'
import { hideAtMd, hideFromMd, styleUpToMd, styleUpToXl } from 'helpers/screens'
import caseStudy from 'assets/images/angi-app-phone.jpg'

export default {
  container: {
    width: '100%',
    paddingLeft: SPACING.large,
    paddingBottom: SPACING.large,
    display: 'flex',
    overflowX: 'hidden',
    '& h4': {
      letterSpacing: SPACING.letters.small
    },
    '& hr': {
      border: 'none',
      height: '1px',
      backgroundColor: COLORS.lightestGrey
    },
    ...styleUpToMd({
      paddingLeft: 0,
      paddingBottom: 0,
      width: '100%',
      display: 'block'
    })
  },
  title: {
    marginTop: 0,
    marginBottom: 0
  },
  featuredImageContainer: {
    backgroundColor: '#cccccc',
    width: '125%',
    marginTop: SPACING.small,
    position: 'relative',
    zIndex: 1,
    backgroundImage: `url(${caseStudy})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    ...styleUpToMd({
      width: '100%',
      marginTop: 0
    })
  },
  featuredImage: {
    maxWidth: '100%',
    float: 'left',
    paddingTop: SPACING.small
  },
  redBoxContainer: {
    margin: '0 -10%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    transform: 'skew(-15deg)',
    backgroundColor: '#fdfcfa',
    zIndex: 1,
    justifyContent: 'center',
    color: COLORS.black,
    '& h4, & h2, & a': {
      color: COLORS.red
    },
    ...hideAtMd
  },
  redBoxContentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    transform: 'skew(15deg)',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '-10%',
    '& hr': {
      marginBottom: SPACING.large,
      marginTop: '.2rem'
    },
    ...styleUpToXl({
      '& hr': {
        marginBottom: SPACING.small
      },
      '& h2': {
        margin: `${SPACING.tiny} 0`
      }
    })
  },
  redBoxContent: {
    width: '50%',
    margin: '8rem auto'
  },
  mobileRedBoxContainer: {
    width: '100%',
    color: COLORS.white,
    ...hideFromMd
  },
  mobileRedBox: {
    backgroundColor: COLORS.red,
    padding: SPACING.base,
    ...hideFromMd
  },
  bottomButton: {
    backgroundColor: COLORS.darkRed,
    flex: '1 1 auto',
    display: 'flex',
    height: SPACING.xxl,
    justifyContent: 'center',
    width: '100%',
    '&:hover': {
      backgroundColor: '#c23526',
      cursor: 'pointer'
    }
  },
  bottomButtonText: {
    position: 'absolute',
    bottom: `-${SPACING.xxl}`,
    lineHeight: SPACING.xxl,
    margin: '0',
    pointerEvents: 'none'
  },
  mobileButtonBottom: {
    padding: SPACING.base,
    backgroundColor: COLORS.darkRed,
    '& h4': {
      textAlign: 'center',
      margin: 0
    }
  }
}
