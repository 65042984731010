import { COLORS } from 'helpers/globals'
import background from 'assets/images/slantPattern.svg'

export default {
  container: {
    backgroundColor: COLORS.red,
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: `6rem 0`,
    textAlign: 'center',
    color: COLORS.white
  },
  heading: {
    fontSize: '2.5rem',
    lineHeight: '2.5rem'
  }
}
