import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import TextLink from 'components/TextLink'

const GetToKnowUs = ({ classes }) => (
  <div className={classes.container}>
    <div className='container'>
      <div className='row'>
        <div className='col-md-8 offset-md-4'>
          <h5>GET TO KNOW US</h5>
          <hr />
          <h2>Our Team</h2>
          <p>
            Finding top-tier software engineers is hard, but we've managed to assemble a
            team of the industries best. We're a laid back, fun-loving crew, passionate
            about the entire process of shipping quality software.
          </p>
          <div className={classes.linkContainer}>
            <TextLink text='JOIN THE TEAM' href='/careers' />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default injectSheet(style)(GetToKnowUs)
