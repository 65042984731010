import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'gatsby-link'

const InternalLink = ({ text, href, classes, includeArrow }) => (
  <Link className={classes.link} to={href}>
    {text} {includeArrow != false && <FontAwesomeIcon icon='arrow-right' />}
  </Link>
)

const ExternalLink = ({ text, href, classes, includeArrow }) => (
  <a className={classes.link} href={href} target='_blank'>
    {text} {includeArrow != false && <FontAwesomeIcon icon='arrow-right' />}
  </a>
)

const TextLink = ({ text, href, classes, includeArrow }) => {
  if (href.match(/^http/)) {
    return <ExternalLink text={text} href={href} classes={classes} includeArrow={includeArrow} />
  } else {
    return <InternalLink text={text} href={href} classes={classes} includeArrow={includeArrow} />
  }
}

export default injectSheet(style)(TextLink)
