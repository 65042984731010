import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import TextLink from 'components/TextLink'

const FeaturedWork = ({ classes }) => (
  <div className={`no-gutters flex-grow-1 ${classes.container}`}>
    <div className={classes.featuredImageContainer} />>
    <div className={classes.mobileRedBoxContainer}>
      <div className={classes.mobileRedBox}>
        <h4 className={classes.title}>CASE STUDY</h4>
        <hr />
        <h2>Angi</h2>
        <p>
          We partnered with Angi to upgrade their monolith to the latest version
          of Ruby and Rails with zero downtime, and without disrupting ongoing
          feature development.
        </p>
        <TextLink text='VIEW PROJECT' href='/blog/angi-case-study' />
      </div>
    </div>
    <div className={classes.redBoxContainer}>
      <div className={classes.redBoxContentContainer}>
        <div className={classes.redBoxContent}>
          <h4 className={classes.title}>CASE STUDY</h4>
          <hr />
          <h2>Angi</h2>
          <p>
            We partnered with Angi to upgrade their monolith to the latest version
            of Ruby and Rails with zero downtime, and without disrupting ongoing
            feature development.
          </p>
          <TextLink text='READ CASE STUDY' href='/blog/angi-case-study' />
          {/* <h4 className={classes.bottomButtonText}>SEE MORE CASE STUDIES</h4> */}
        </div>
      </div>
    </div>
  </div>
)

export default injectSheet(style)(FeaturedWork)
