import { COLORS, SPACING } from 'helpers/globals'
import { hideAtMd, styleUpToSm } from 'helpers/screens'
import image from 'assets/images/services-overview.jpg'

export default {
  container: {
    color: COLORS.taupe,
    '& hr': {
      border: `1px solid ${COLORS.lightGrey}`,
      margin: `${SPACING.large} 0`,
      clear: 'both'
    }
  },
  topHR: {
    marginTop: 0
  },
  bottomBarContainer: {
    ...hideAtMd,
    height: SPACING.base,
    '& div:first-of-type': {
      backgroundColor: COLORS.red
    }
  },
  image: {
    backgroundImage: `url(${image})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minHeight: '300px !important'
  },
  content: {
    // height: '600px',
    padding: '6rem !important',
    ...styleUpToSm({
      paddingLeft: `${SPACING.large} !important`,
      paddingRight: `${SPACING.large} !important`
    }),
    '& h2': {
      marginTop: 0,
      lineHeight: SPACING.large
    }
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    letterSpacing: SPACING.letters.small,
    float: 'left'
  },
  textLink: {
    [`@media (max-width: 360px)`]: {
      display: 'none'
    },
    position: 'relative',
    top: '-.2rem',
    float: 'right',
    '& a': { color: `${COLORS.red} !important` }
  },
  services: {
    color: COLORS.red,
    '& h4': {
      marginTop: 0,
      letterSpacing: SPACING.letters.small,
      marginBottom: SPACING.small
    },
    '& h4:last-of-type': { marginBottom: 0 }
  }
}
