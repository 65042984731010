import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import image from 'assets/images/slashesLarge.svg'

const OurProcess = ({ classes }) => (
  <div className={classes.container}>
    <img src={image} className={classes.slash} alt='' />
    <div className='container'>
      <div className='row'>
        <div className='col-md-8'>
          <h2>Our Mission</h2>
          <p>
            <strong>Happy people create better software.</strong> Every day we're building towards
            a development process where everyone wins regardless of where they sit in the
            software development lifecycle.
          </p>
          <p>
            <strong>The mission:</strong> To help software teams reach their full potential.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default injectSheet(style)(OurProcess)
