import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import { Location } from '@reach/router'
import { withPrefix } from 'gatsby'
import { Helmet } from 'react-helmet'
import Nav from './Nav'
import FeaturedWork from './FeaturedWork'
import slants from 'assets/images/vSlants.png'
import TextLink from 'components/TextLink'

const Header = ({ classes }) => (
  <div className={`${classes.outerContainer} d-flex flex-column`}>
    <Location>
      {({ location }) => {
        return (
          <Helmet
            meta={[
              {
                property: 'og:image',
                content: `${location.origin}/${withPrefix('/img/logo.svg')}`
              },
              { property: 'og:url', content: location.href }
            ]}
          />
        )
      }}
    </Location>
    <div className={classes.container}>
      <Nav />
      <img className={classes.slants} src={slants} />
      <div className={`container ${classes.innerContainer}`}>
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-10'>
            <h1>We're on a mission to help software teams reach their full potential.</h1>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-10 col-lg-8'>
            <h3>
              Hint <strong>unlocks</strong> potential through innovative <strong>DevOps</strong>{' '}
              practices. We're all about <strong>people</strong>,{' '}
              <strong>improvement</strong>, and <strong>integrity</strong>.
            </h3>
            <TextLink text="LET'S TALK" href='contact' />
          </div>
        </div>
      </div>
    </div>
    <FeaturedWork />
  </div>
)

export default injectSheet(style)(Header)
